import * as React from 'react';
import classNames from 'classnames';
import { Trans } from 'gatsby-plugin-react-i18next';
import { Highlight } from '../highlight/highlight';
import './card.scss';

export type CardType = {
  title: string;
  text: string;
  image: string;
  imageAlt: string;
  isReversed?: boolean;
  className?: string;
}

export const Card: React.FC<CardType> = ({
  text, title, image, imageAlt, isReversed, className,
}) => (
  <article className={classNames('card', { reversed: isReversed }, className)}>
    <h2>
      <Trans
        i18nKey={title}
        components={{ highlight: <Highlight /> }}
      />
    </h2>
    <div className="card__image">
      <img src={image} alt={imageAlt} />
    </div>
    <div className="card__info">
      <p>
        <Trans
          i18nKey={text}
          components={{
            highlight: <Highlight />,
            a: <a target="_blank" />,
          }}
        />
      </p>
    </div>
  </article>
);
