import * as React from 'react';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/layout';
import { Card, CardType } from '../components/ui/card/card';

const WhatElseCanIDo: React.FC = () => {
  const { t } = useTranslation();

  const cards: CardType[] = t('pages.canIdo.cards', { returnObjects: true });

  return (
    <Layout className="main__help">
      <>
        <Helmet>
          <meta name="keywords" content={t('pages.canIdo.metaKeywords')} />
          <meta name="description" content={t('pages.canIdo.metaDescription')} />
          <title>{t('pages.canIdo.documentTitle')}</title>
        </Helmet>
        <div className="container--fluid">
          <h1>{t('pages.canIdo.title')}</h1>
          <div className="card-list">
            {cards.map(({
              title, text, image, imageAlt,
            }) => (
              <Card
                key={text}
                text={text}
                title={title}
                image={`../../images/${image}`}
                imageAlt={imageAlt}
                isReversed={true}
              />
            ))}
          </div>
          <div className="info">
            <p>
              <Trans
                i18nKey="pages.canIdo.info"
                components={{
                  a: <a target="_blank" />,
                }}
              />
            </p>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default WhatElseCanIDo;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
